//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller
{
  static values =
  {
    handle: String,
    responseKind: { type: String, default: 'turbo-stream' },
    positionParam: { type: String, default: 'position' }
  }

  initialize()
  {
    this.onUpdate = this.onUpdate.bind( this );
  }

  connect()
  {
    let options = { onUpdate: this.onUpdate, handle: this.handleValue };
    this.sortable = new Sortable( this.element, options );
  }

  disconnect()
  {
    this.sortable.destroy();
    this.sortable = undefined
  }

  async onUpdate( { item, newIndex } )
  {
    if( !item.dataset.sortUrl ) return;
    const data = new FormData();
    data.append( this.positionParamValue, newIndex + 1 );
    await patch( item.dataset.sortUrl, { body: data, responseKind: this.responseKindValue });
  }
}