//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import consumer from "../channels/consumer";

export default class extends Controller
{
  static classes = [ 'online', 'offline', 'info' ];

  connect()
  {
    this.element.classList.add( this.infoClass );

    this.channelSubscription = consumer.subscriptions.create(
      {
        channel: "KasseChannel",
      },
      {
        connected:    this.channelConnect.bind( this ),
        disconnected: this.channelDisconnect.bind( this ),
        received:     this.channelReceive.bind( this )
      }
    );

    this.status = 'Connecting...';
  }

  disconnect()
  {
    this.channelSubscription.unsubscribe();
  }

  refresh()
  {
    Turbo.cache.clear();
    Turbo.visit( window.location, { action: 'replace' } );
  }

  channelConnect()
  {
    if( this.status == 'Offline' )
    {
      this.refresh();
    }
    else
    {
      console.log( 'Connected to Kasse.' );
      this.element.classList.remove( this.infoClass );
      this.element.classList.add( this.onlineClass );
      this.status = 'Online';
    }
  }

  channelDisconnect()
  {
    console.log( 'Disconnected from Kasse.' );
    this.element.classList.remove( this.onlineClass );
    this.element.classList.add( this.offlineClass );
    this.status = 'Offline';
  }

  channelReceive( data )
  {
    console.log( `Received data from Dev: ${data}` );

    clearTimeout( this.infoTimer );
    this.element.classList.add( this.infoClass );
    this.infoTimer = setTimeout( () =>
      {
        this.element.classList.remove( this.infoClass );
      }, this.infoTime );
  }

  get infoTime()
  {
    if( this.hasInfoTimeValue ) return this.infoTimeValue;
    return 1000;
  }

  get status()
  {
    return this._status;
  }

  set status( status )
  {
    if( status == this._status ) return;
    this._status = status;
    this.element.setAttribute( 'title', status );
  }
}