//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';

export default class extends Controller
{
  static targets = [ 'date', 'time' ];

  connect()
  {
    this.timeFormat = new Intl.DateTimeFormat( undefined, { timeStyle: "short" } );
    this.dateFormat = new Intl.DateTimeFormat( undefined, { day: "numeric", month: "long", year: "numeric" } );
    this.interval = setInterval( () =>
    {
      this.dateTarget.innerHTML = this.dateFormat.format( new Date() );
      this.timeTarget.innerHTML = this.timeFormat.format( new Date() );
    }, 1000 );
  }
}