//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';

export default class extends Controller
{
  static targets = [ 'query', 'submit' ];
  static values = { scanner: String }
  static classes = [ 'busy' ];

  connect()
  {
    this.currentQueryTimer = null;
    this.currentQueryResultID = null;

    this.element.addEventListener( 'turbo:submit-start', this.submitStart.bind( this ));
    this.element.addEventListener( 'turbo:submit-end', this.submitEnd.bind( this ));

    this.queryTarget.focus();
  }

  scan( e )
  {
    if( e.target.tagName != 'BODY' ) return;

    if( e.key == 'Tab' || e.key == 'Enter' )
    {
      if( this.scannerValue.length > 3 )
      {
        clearTimeout( this.currentQueryTimer );
        this.queryTarget.value = this.scannerValue;
        this.scannerValue = '';
        this.element.requestSubmit();
      }
    }
    else if( e.key.match( /^\S$/ ) )
    {
      this.scannerValue += e.key;
    }

    e.preventDefault();
    this.queryTarget.focus();
  }

  change( e )
  {
    e.preventDefault();

    // Stop current query timer
    clearTimeout( this.currentQueryTimer );

    const query = this.queryTarget.value;

    if( query.length > 2 )
    {
      // Start query timer
      this.currentQueryTimer = setTimeout( () =>
      {
        this.element.requestSubmit();
      }, 500 );
    }
    else if( query.length == 0 )
    {
      this.element.requestSubmit();
    }
  }

  reset( e )
  {
    e.preventDefault();

    this.queryTarget.value = '';
    clearTimeout( this.currentQueryTimer );
    this.element.requestSubmit();
    this.queryTarget.focus();
  }

  focus( e )
  {
    if( this.scannerValue.length > 0 )
    {
      e.target.value = this.scannerValue;
      this.scannerValue = '';
    }
    else
    {
      e.target.select();
    }
  }

  submitStart( e )
  {
    this.submitTarget.setAttribute( 'disabled', true );
    this.element.classList.add( ...this.busyClass.split( ' ' ) );
  }

  submitEnd( e )
  {
    this.element.classList.remove( ...this.busyClass.split( ' ' ) );
    this.submitTarget.removeAttribute( 'disabled' );
  }
}